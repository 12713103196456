import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CenterLogo from "../components/CenterLogo"
import Logo from "../components/Logo"
import styled from "styled-components"
import ImgDesign825 from "../images/Design-Development/Design-825-825.jpg"
import ImgModel825 from "../images/Design-Development/Model-825-825.jpg"

import PaddingLeftLogo from "../components/PaddingLeftLogo"
import Bg from "../components/Bg"
import H2 from "../components/H2"
import ContactFormer from "../components/ContactFormer"
import UpdatedTimeLine from "../containers/DesignDev/UpdatedTimeLine"
const Relative = styled.div`
  position: relative;
`

const BgByj = styled.div`
  background-repeat: no-repeat;
  min-height: 60vh;
  background-size: cover;
  background-position: center;
  background-image: url("https://res.cloudinary.com/dh9k0znwt/image/upload/v1680073075/Page%203%20Design%20Development/Design_Development_1920x680_i1mxbd.jpg");
  h1 {
    padding-top: 20vh;
  }
`
const H1 = styled.h1`
  text-shadow: 1px 1px 1px rgb(41, 41, 41);
`
const LeftLogo = styled.div`
  width: 130px;
  height: 130px;
  display: block;
  position: absolute;
  top: -40px;
  right: 0;
  left: 0;

  @media (max-width: 768px) {
    width: 90px;
    height: 90px;
    top: -20px;
  }
`

const DesignDevPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="flex flex-wrap py-16 bg-white xl:pt-32 pt-24">
      <div className="item w-full h-auto text-center md:py-9 py-3  2xl:pt-4">
        <BgByj>
          <H1 className="text-white md:text-6xl text-5xl drop-shadow-lg shadow-black">
            Design &amp; Development
          </H1>
        </BgByj>
      </div>
    </div>
    <div className="flex flex-wrap pb-16 bg-white">
      <div className="item w-full h-auto text-center md:py-9 py-3">
        <Relative>
          <CenterLogo>
            <Logo />
          </CenterLogo>
          <H2 className="md:text-6xl text-4xl"> Design &amp; Development</H2>
        </Relative>
      </div>
    </div>

    <div className="container mx-auto">
      <div className="flex flex-wrap">
        <div className="item  w-full md:w-full lg:w-1/2 h-full sm:order-1  md:order-1 lg:order-1">
          <img
            className="object-contain md:object-scale-down f-r"
            alt="a changer"
            src={ImgDesign825}
          />
        </div>
        <div className="item  w-full md:w-full lg:w-1/2 h-auto order-4  md:order-4 lg:order-3 md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 py-2">
              Design
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            We have a team of 10 in-house professional jewelry designers with
            many years of experience. Our team is made up of designers who can
            sketch by hand,with design illustrator programs, CAD designers as
            well as graphic designers.We also work with local freelance
            designers and international designers for special projects.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap  bg-white">
        <div className="item w-full h-auto text-center md:py-9 py-3 xl:pt-20 2xl:pt-20">
          <Relative>
            <CenterLogo>
              <Logo />
            </CenterLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl text-4xl pl-6 md:pl-10 lg:pt-2 py-2 pt-4 xl:pt-2 2xl:pt-0">
              Development
            </H2>
          </Relative>
        </div>
      </div>
      <div className="flex flex-wrap pt-16 bg-white">
        <div className="item  w-full md:w-full lg:w-1/2 h-auto order-4  md:order-4 lg:order-3 md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl  text-4xl pl-6 md:pl-10 lg:pt-2 py-2">
              Private Label
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            We offer the development of complete private label collections from
            start to finish. Beginning with trend &amp; market research and
            determining target sales prices &amp; cost-based pricing. We then
            would develop a whole range of products suited to your goals.
          </p>
        </div>
        <div className="item  w-full md:w-full lg:w-1/2 h-full order-3  md:order-3 lg:order-4">
          <img
            className="object-contain md:object-scale-down f-l"
            alt="a changer"
            src="https://res.cloudinary.com/dh9k0znwt/image/upload/v1680073075/Page%203%20Design%20Development/Private_label_825x825_tqkq0h.jpg"
          />
        </div>
        <div className="item  w-full md:w-full lg:w-1/2 h-full order-5  md:order-5 lg:order-5">
          <img
            className="object-contain md:object-scale-down f-r"
            alt="a changer"
            src={ImgModel825}
          />
        </div>
        <div className="item w-full md:w-full lg:w-1/2 h-auto order-6  md:order-6 lg:order-6   md:py-8 md:px-10 py-12 px-4">
          <PaddingLeftLogo />
          <Relative>
            <LeftLogo>
              <Logo />
            </LeftLogo>
            <H2 className="2xl:text-5xl xl:text-4xl lg:text-4xl md:text-4xl  text-4xl pl-6 md:pl-10 lg:pt-2 py-2">
              Custom design
            </H2>
          </Relative>
          <p className="md:text-2xl text-xl leading-8  text-left pt-14 pb-8 md:pl-12 md:py-16">
            You are also welcome to provide your own designs and let us know
            your ideas with photos and rough sketches. Our team will work on
            detailed design suggestions according to your requirements. We can
            make proposals on your briefing and our experience in close
            communication with you and our production team.
          </p>
        </div>
      </div>
      <UpdatedTimeLine />
    </div>
    <Bg>
      <ContactFormer />
    </Bg>
  </Layout>
)

export default DesignDevPage
