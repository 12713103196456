import React from "react"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import styled from "styled-components"
import ImgPolish from "../../images/iStock-173625827.jpg"
import { Check } from "@styled-icons/entypo"

const WhiteCheck = styled(Check)`
  color: white;
  width: 30px;
`
const BgCom = styled.div`
  width: 80%;
  margin: auto;
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center;
  background-image: url(${ImgPolish});

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
const TimeLine = () => {
  return (
    <div className="flex flex-wrap overflow-hidden container mx-auto py-12 md:py-24">
      <div className="w-full overflow-hidden">
        <BgCom>
          {" "}
          <div className="py-24">
            <VerticalTimeline>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={{
                  background: "#323232",
                  color: "#323232",
                }}
              >
                <h3 className="vertical-timeline-element-title text-white text-3xl md:pb-8 pb-2">
                  Determining the market
                </h3>
                <ul class="list-none text-white text-lg">
                  <li>
                    <WhiteCheck /> Definition target group
                  </li>
                  <li>
                    <WhiteCheck />
                    Proposal of raw material &amp; sourcing of new materials
                  </li>{" "}
                  <li>
                    <WhiteCheck />
                    Products range and size of collection
                  </li>{" "}
                  <li>
                    <WhiteCheck />
                    Rough pricing &amp; calculation
                  </li>
                </ul>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={{
                  background: "#323232",
                  color: "#323232",
                }}
              >
                <h3 className="vertical-timeline-element-title text-white text-3xl md:pb-8 pb-2">
                  Cost &amp; Timing
                </h3>
                <ul class="list-none text-white text-lg">
                  <li>
                    <WhiteCheck /> Setting time frame for development &
                    production
                  </li>
                  <li>
                    <WhiteCheck />
                    Budgeting costs for design and Modelmaking
                  </li>{" "}
                  <li>
                    <WhiteCheck />
                    Finalizing goals, terms &amp; conditions
                  </li>{" "}
                </ul>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                iconStyle={{
                  background: "#323232",
                  color: "#323232",
                }}
              >
                <h3 className="vertical-timeline-element-title text-white text-3xl md:pb-8 pb-2">
                  Briefing
                </h3>
                <ul class="list-none text-white text-lg">
                  <li>
                    <WhiteCheck /> Create a detailed briefing for the client and
                    design team
                  </li>
                </ul>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{
                  background: "#323232",
                  color: "#323232",
                }}
              >
                <h3 className="vertical-timeline-element-title text-white text-3xl md:pb-8 pb-2">
                  Design
                </h3>
                <ul class="list-none text-white text-lg">
                  <li>
                    <WhiteCheck /> First design proposals &amp; rough sketches
                    with initial cost estimates.
                  </li>
                  <li>
                    <WhiteCheck />
                    Detailed sketches with material breakdown (stones, plating,
                    surface finishing)
                  </li>{" "}
                </ul>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{
                  background: "#323232",
                  color: "#323232",
                }}
              >
                <h3 className="vertical-timeline-element-title text-white text-3xl md:pb-8 pb-2">
                  Modelmaking
                </h3>
                <ul class="list-none text-white text-lg">
                  <li>
                    <WhiteCheck /> After approval of design master models are
                    produced by our CAD model makers in conjunction with our
                    experienced goldsmiths.
                  </li>
                </ul>
              </VerticalTimelineElement>

              <VerticalTimelineElement
                className="vertical-timeline-element--education"
                iconStyle={{
                  background: "#323232",
                  color: "#323232",
                }}
              >
                <h3 className="vertical-timeline-element-title text-white text-3xl md:pb-8 pb-2">
                  Production
                </h3>
                <ul class="list-none text-white text-lg">
                  <li>
                    <WhiteCheck /> Once the quality samples are approved,
                    production on the collection begins.
                  </li>
                </ul>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        </BgCom>
      </div>
    </div>
  )
}

export default TimeLine
